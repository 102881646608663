import { Component, lazy } from 'react'
import { connect } from 'react-redux';
import priceServices from "../services/priceServices";
import AuthUtils from "../services/authService";
import {SET_LOADING, SET_MY_BALANCE, SET_OPEN_LOGIN_WINDOW} from "../reducer/Main.reducer";
import {Trans, withTranslation} from "react-i18next";
import ProgressBar from "@ramonak/react-progress-bar";
import {Modal, Spin} from "antd";
import AntBoxInfo from "../common/AntBoxInfo";
import AntBox from "../common/AntBox";
import { apiEndpoint } from '../common/open2web3';
const AchatToken = lazy(() => import('../common/AchatToken'));
const BalancesList = lazy(() => import('../common/BalancesList'));

class TokenSale extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openTokenSale: true,
      showVerificationUnsuccessfulModal: false,
      verificationInProgress: this.isEmailVerification(),
      showVerificationSuccessful:false,
      minMax: ''
    }
  }

  isTokenSaleOpen = () => this.state.openTokenSale;

  isEmailVerification = () => window.location.href.includes('verify-account');


  componentDidMount() {
    priceServices.getMyBalance(AuthUtils.getUsername()).then((d) => {
      this.props.dispatch({ type: SET_MY_BALANCE, payload: d?.totalBalance ?? 0 })
    })
    const params = Object.fromEntries((new URLSearchParams(window.location.search)).entries());
    if (params?.with_login) {
      this.props.dispatch({ type: SET_OPEN_LOGIN_WINDOW, payload: true })
    }
    console.log(this.state.verificationInProgress);
    if(this.isEmailVerification()){
        this.startEmailVerificationWorkflow();
    }

    fetch(apiEndpoint + "/services/LabarakIcoDB/queryExecutor/queries/tokenInfos", { hasAuth: false })
      .then(response => response.json())
      .then(data => {
        let min = data.minValueToInvest;
        let max = data.maxValueToInvest; 
        this.setState({
          minMax: `Min ${min >= 1000 ? min / 1000 + 'K' : min}€/Max ${max >= 1000 ? max / 1000 + 'k' : max}€`
        })
      })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.store.user && this.props.store.user?.username) {
      priceServices.getMyBalance(AuthUtils.getUsername()).then((d) => {
        this.props.dispatch({ type: SET_MY_BALANCE, payload: d?.totalBalance ?? 0 })
      })
    }
  }

  startEmailVerificationWorkflow(){
    this.props.dispatch({ type: SET_LOADING, payload: true });
    console.log("EMAIL VERIFICIAOTN IS ON");
    let searchParams = new URLSearchParams(window.location.search);
    let code = searchParams.get('code');
    let username = searchParams.get('username');
    console.log(code, username);
    this.verifyRegistration(code, username).then((data)=>{
      if(data){
        this.setState({
          verificationInProgress: false,
          showVerificationSuccessful: true,
        })
      }else {
        this.setState({
          verificationInProgress: false,
          showVerificationUnsuccessfulModal: true,
        })
      }
    }).catch((e)=>{
      console.log(e);
      this.setState({
        verificationInProgress: false,
        showVerificationUnsuccessfulModal: true,
      })
    }).finally(()=>{
      this.props.dispatch({ type: SET_LOADING, payload: false });
    })
  }

  verifyRegistration(code, username){
    return AuthUtils.verifyUserCode(code, username)
  }

  toHomePage(){
    this.props.history.push('/', {loginAction: true})
    this.props.dispatch({type: SET_OPEN_LOGIN_WINDOW, payload: true});
  }

//   closeEmailVerificationModal() {
//     this.setState({
//         emailVerificationModal: false,
//         registrationModal: false,
//         signUpViewFirst: false,
//     })
// }

  render() {
    /* return (
      <div className="Coming-soon">
        <img className="logo" src="/images/logo.png" alt="ANT ICO" />
        <span>Coming soon</span>
      </div>
    ); */
    const { t } = this.props;
    // const connected = !!this.props.store.walletAddress;
    return (
      <div>
        <div className="container pt-50">
          <div className="row sMobileP" style={{ display: 'flex' }}>
            <div className="col-sm-7 col-md-7 col-xs-12 bgtok">
              {
                this.isTokenSaleOpen() ? <AchatToken /> :
                  <div className="achat" style={{ height: "100%" }}>
                    <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                      <div className="flex flex-wrap items-center">
                        <div className="relative w-full max-w-full flex-grow flex-1">
                          <h2 className="text-xl font-semibold">{t('Token sale is not available yet, please keep an eye on our community pages for further notice')}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
              }

            </div>
            <div className="col-sm-5 col-md-5 col-xs-12">
              <div className="row">
                <div className="col-md-6 col-sm-6 p0M"><AntBoxInfo.TotalSupplay /></div>
                <div className="col-md-6 col-sm-6 p0M"><AntBoxInfo.Price /></div>
                <div className="col-md-6 col-sm-6 p0M"><AntBox title={t('Investment range')} value={this.state.minMax}/></div>
                <div className="col-md-12 col-sm-12" style={{paddingRight:20 ,paddingLeft:20}} >
                <h3 className="textC">{t('Current step: phase ' + this.props.store.icoRound)}</h3>
                {this.props.store.salesPercentLoaded && this.props.store.salesPercent >=25 && 
                <Spin spinning={!this.props.store.salesPercentLoaded}>
                  <ProgressBar className="progressB" height={40} labelAlignment={'center'} completed={this.props.store.salesPercent} />
                </Spin> 
                }               
                </div>

                <div className="col-md-6 col-sm-6 p0M"><AntBoxInfo.TotalPhase1 /></div>
                {/* <div className="col-md-6 col-sm-6"><AntBoxInfo.TotalTokensSold /></div> */}
                <div className="col-md-6 col-sm-6 p0M"><AntBoxInfo.TokensAvailable /></div>
                {/*<div className="col-md-4 col-sm-6"><AntBoxInfo.Time /></div>*/}
                {/*{ connected ? <div className="col-md-6 col-sm-6"><AntBoxInfo.MyBalance /></div> : null }*/}
                {AuthUtils.isConnectedViaEmail() ? <div className="col-md-6 col-sm-6 p0M"><AntBoxInfo.myVerifiedBalance/></div> : null}
                {/*  { connected ? <div className="col-md-4 col-sm-6"><AntBoxInfo.Wallet /></div> : null }*/}
                {/*{ connected ? <div className="col-md-4 col-sm-6"><AntBoxInfo.Wallet /></div> : null }*/}

                {/*<div className="col-md-4 col-sm-6"><AntBoxInfo.Weiraised /></div> */}
                {/*<div className="col-md-6 col-sm-6"><AntBoxInfo.WalletContract /></div>*/}
              </div>
            </div>

          </div>
          <BalancesList />
          {/*
          <TransactionsList />
          */}
        </div>
        <Modal maskStyle={{ filter: "blur(10.5rem)"}} visible={this.state.verificationInProgress} title={t("Email Verification")} footer={<div style={{ display: 'flex', justifyContent: 'center' }}><button className="ant-btn ant-btn-primary" onClick={e => this.closeEmailVerificationModal()} >OK</button></div>} onOk={e => this.closeEmailVerificationModal()} onCancel={e => this.closeEmailVerificationModal()} >
          <p><Trans i18nKey="We are verifying your email and subsequent data, please wait a moment"/></p>
        </Modal>

        <Modal visible={this.state.showVerificationUnsuccessfulModal}
               title={t("Email Verification Unsuccessful")}
               maskStyle={{ filter: "blur(10.5rem)"}}
               footer={
                 <div style={{ display: 'flex', justifyContent: 'center' }}>
                   <button className="ant-btn ant-btn-primary" onClick={e => this.toHomePage()} >Login</button>
                 </div>
               }
               

               onOk={e => null}
               onCancel={e => null} >
          <p><Trans i18nKey="Your verification wasn't successful, Please do contact our support or moderators for further clarifications"/></p>
        </Modal>


        <Modal visible={this.state.showVerificationSuccessful}
               title={t("Email Verification Successful")}
               maskStyle={{ filter: "blur(10.5rem)" }}
               footer={
                 <div style={{ display: 'flex', justifyContent: 'center' }}>
                   <button className="ant-btn ant-btn-primary" onClick={e => this.toHomePage()} >Login</button>
                 </div>
               }
               onOk={e => this.toHomePage()}
               onCancel={e => this.toHomePage()} >
          <p><Trans i18nKey="Your verification was successful, Please do login to your account via the link below"/></p>
        </Modal>

      </div>
    )
  }
}

const mapStateToProps = (store) => ({ store });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TokenSale));

