import { useEffect, useState } from 'react'
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { SET_KYC, SET_KYC_STATE } from '../reducer/Main.reducer';
import { apiEndpoint, BlockPassId, open2web3 } from './open2web3';

function KYCConnectButton({ store, dispatch }) {
  const { walletConnected, walletAddress,user } = store;
  const [state, setState] = useState('initial');
  const [kycInterval, setKycInterval] = useState(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!state) {
      dispatch({ type: SET_KYC, payload: true });
    } else {
      dispatch({ type: SET_KYC, payload: false });
    }
  }, [state]);

  useEffect(() => {
    dispatch({ type: SET_KYC_STATE, payload: state });
  }, [state]);

  const verifKyc = async () => {
    
    var interval = setInterval(async function () {
      var value = await verifieIfUserAccountValidated();
      setState(value)
      if (interval != null && value == null) {
        clearInterval(interval);
      }
    }, 3000);

    setKycInterval(interval);

  }

  const verifieIfUserAccountValidated = () => {
    return new Promise((resolve, reject) => {
      var account = walletAddress ? walletAddress : user?.username
      if (account) {
        const api = apiEndpoint;
        //setTimeout(() => {
          if (state !== 'initial' && state !== 'checking' && state !== null) setState('initial');
          fetch(api + "/services/LabarakIcoDB/queryExecutor/queries/selectStatutKycFromByPublicAddress?publicAddress=" + user?.username)
            .then(response => response.json())
            .then(res => {
              if (res.errors) {


              } else {
                //console.log("kyc status",res.content)
                if (res.content.length > 0) {
                  if (res.content[0].statutVerification == "active") {
                    //setState(null);
                    resolve(null)
                  } else if(res.content[0].statutVerification == 'attente'){
                    setState('show');
                    resolve('show');
                    //verifieIfUserAccountValidated();
                  }else{
                    resolve('pending');
                  }
                } else {
                  //setState('show');
                  resolve('show');
                  //verifieIfUserAccountValidated();
                }
              }
            }).catch((err)=>{
              // Do nothing for the moment.
          })
        //}, 1000);
      } else {
        //setState(null);
        resolve(null);
      }
    });

  }

  const checkingKYC = () => {
    setState('checking');
    open2web3.isWhitelisted(walletAddress).then(result => {
      console.log({ result, walletAddress });
      if (!result) {
        if (localStorage.getItem('kyc_' + walletAddress)) {
          setState('pending');
        } else {
          setState('show');
        }
      } else {
        setState(null);
      }
    }).catch(error => {
      console.log("checkingKYC error", error);
    });
  };
  useEffect(async () => {
    console.log(user)
    if (kycInterval != null) {
      clearInterval(kycInterval);
    }
    setState(null);
    if (walletAddress || user?.username) {
      setState("checking")
      verifKyc();

      //checkingKYC();
      /*setState("checking")
      var value = await verifieIfUserAccountValidated();
      setState(value)
      while(value == "pending" || value == "show"){
        value = await verifieIfUserAccountValidated();
      }*/
    }
  }, [walletAddress,user])

  useEffect(() => {
    const blockpass = null;
    var account = walletAddress ? walletAddress : user?.username
    if (account && state === 'show') {
      const blockpass = new window.BlockpassKYCConnect(BlockPassId, { env: 'prod', refId: account, mainColor: '030239' });
      blockpass.startKYCConnect();
      blockpass.on('KYCConnectLoad', () => {
        console.log("KYCConnectLoad")
        //verifieIfUserAccountValidated();
      })
      blockpass.on('KYCConnectSuccess', () => {
        setState('pending');
      });
      blockpass.on('KYCConnectClose', () => {
        //checkingKYC();
      });
    }
    return () => {
      if (blockpass) blockpass.stopKYCConnect();
    }
  }, [walletAddress, state]);


  useEffect(() => {
    const t = setInterval(() => {
      setIsActive(!!document.getElementById('blockpass-kyc-web'));
    }, 500);
    return () => {
      clearInterval(t);
    }
  }, [])

  /*useEffect(() => {
    let t;
    if (!isActive && walletAddress) {
      let v = 0; 
      t = setInterval(() => {
        v = v + 1;
        checkingKYC();
        if (v > 3) {
          clearInterval(t);
        }
      }, 3000);
    }
    return () => {
      if (t) clearInterval(t);
    }
  }, [isActive]);*/


  if (!state) return null;
  if (state === 'pending') return <div style={{ position: 'relative',marginBottom:70 }}><div className="kyc-content"><Trans i18nKey="Votre dossier est en attente de validation par un administrateur" /></div></div>;
  if (state === 'checking') return <div style={{ position: 'relative',marginBottom:70 }}><div className="kyc-content"><Trans i18nKey="Vérification de KYC ..." /></div></div>;
  return (
    <div style={{ position: 'relative',marginBottom:70 }}><div className="kyc-content"><a style={{ cursor: 'pointer', display: walletConnected ? 'inline-block' : 'none' }} id="blockpass-kyc-connect"> <span className="visible-text"><Trans i18nKey="Cliquer ici" /></span> <Trans i18nKey="Terminez votre KYC avec notre partenaire  Blockpass Identity" /></a></div></div>
  )
}

const mapState = (store) => ({ store });
const mapDispatch = (dispatch) => ({ dispatch });

export default connect(mapState, mapDispatch)(withTranslation()(KYCConnectButton));
