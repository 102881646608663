import React, { Component } from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { connect } from "react-redux";
import {
    SET_AUTH_TOKEN,
    SET_ISREFFERAL_ACTOR,
    SET_BALANCES_ACCOUNT,
    SET_LOADING, SET_OPEN_LOGIN_WINDOW,
    SET_OWNER_WALLET,
    SET_REFRESH, SET_USER,
    SET_WALLET,
    SET_OPEN_SIGN_UP_WINDOW
} from "../reducer/Main.reducer";
import { open2web3, CrowsdaleContractAddress, apiEndpoint, ChainProps, AppNetworkId, getOrCreateSignature, AppNetworkName } from "./open2web3";
import { withRouter } from "react-router";
import { debounce } from "@material-ui/core";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import qs from "qs"
import SingUpView from "./SingUpview";
import AuthService from '../services/authService';
import { Trans, withTranslation } from "react-i18next";
import priceServices from "../services/priceServices";
import { t } from "i18next";
import i18n from "../i18n";

function debounceg(func, timeout = 300){
    let timer;
    return (...args) => {
      if (!timer) {
        func.apply(this, args);
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
      }, timeout);
    };
  }

const providerOptions = {
    /*"custom-bsc": {
        display: {
            logo: base64BscWalletLogo,
            name: "Binance Chain Wallet",
            description: "Connect to your Binance chain wallet account"
        },
        //package: "ExampleProvider",
        package: BscConnector,

        connector: async (ProviderPackage, customNodeOptions) => {
            const bsc = new BscConnector({
                supportedChainIds: [56, 97] // later on 1 ethereum mainnet and 3 ethereum ropsten will be supported
            })
            await bsc.activate();
            await bsc.getAccount();
            await bsc.getChainId();
            return bsc.getProvider();
        }
    }*/
};


class WalletConnectButton extends Component {

    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
      }
      
    state = {
        fetching: false,
        address: "",
        web3: null,
        provider: null,
        connected: false,
        chainId: 1,
        networkId: 1,
        assets: [],
        showModal: false,
        pendingRequest: false,
        result: null,
        modalVisible: false,
        registrationModal: (this.props.store.openAuthWindow ?? false) || (this.props.store.openSignUpWindow ?? false)  ,
        errorModalVisible: false,
        faildModalVisible: false,
        alreadySubscribModal:false,
        emailVerificationModal: false,
        signUpViewFirst: false,
        registrationModalKey: (new Date()).toJSON()
    }

    isKycValidationNotInitiate() {
        return new Promise((resolve,reject) => {
            let intervalID = setInterval(() => {
                let kycValidState = this.props.store.kycValidState;
                if(kycValidState === 'show'){
                    clearInterval(intervalID);
                    resolve(true);
                }
                if(kycValidState === 'pending' || kycValidState === null){
                    clearInterval(intervalID);
                    reject(false);
                }
            },10)
        })
    }

    openErrorModal() {
        this.setState({
            errorModalVisible: true
        })
    }

    hideGlobalLoginModal = async () => {
        this.props.dispatch({ type: SET_OPEN_LOGIN_WINDOW, payload: false })
        this.props.dispatch({ type: SET_OPEN_SIGN_UP_WINDOW, payload: false })
    }

    metamaskConnect = async () => {
        if (typeof web3 !== 'undefined') {
            window.localStorage.setItem('logout', 'false');
            this.props.dispatch({ type: SET_LOADING, payload: true });
            try {
                const {
                    web3,
                    provider,
                    address,
                    chainId,
                    networkId
                } = await open2web3.connect({ providerOptions });
                this.subscribeProvider(provider);
                this.setState({
                    web3,
                    provider,
                    connected: true,
                    address,
                    chainId,
                    networkId
                });



                /*if (this.state.chainId * 1 !== AppNetworkId) {
                    provider
                        .request({
                            method: 'wallet_addEthereumChain',
                            params: [ChainProps],
                        })
                        .then((result) => {
                            console.log("wallet_addEthereumChain", result);
                        })
                        .catch((error) => {
                            if (error.code === 4001) {
                                console.log("We can't encrypt anything without the key.", error);
                            } else {
                                console.error(error);
                            }
                        });
                }*/

                /*if (this.state.chainId * 1 !== AppNetworkId) {
                    provider
                        .request({
                            method: 'wallet_switchEthereumChain',
                            params: [ChainProps],
                        })
                        .then((result) => {
                            console.log("wallet_addEthereumChain", result);
                        })
                        .catch((error) => {
                            if (error.code === 4001) {
                                console.log("We can't encrypt anything without the key.", error);
                            } else {
                                console.error(error);
                            }
                        });
                }*/



            } catch (err) {
                console.log("err message:", err)
                if (err == undefined) {
                    this.setState({
                        modalVisible: true
                    });
                }

            }
            this.props.dispatch({ type: SET_LOADING, payload: false });
        } else {
            this.setState({
                modalVisible: true
            });
            this.props.dispatch({ type: SET_LOADING, payload: false });
        }
    }


    getUserBalances = () => {



        fetch(apiEndpoint + "/services/myExternalPayment/allBonuses")
            .then(response => response.json())
            .then(allBonuses => {
                if (allBonuses.errors) {
                    throw allBonuses.errors
                } else {
                    priceServices.getMyBalance(this.state.address).then((d) => {
                        let newBalances = d.balances.content?.map((e) => {
                            return { [e.status]: e }
                        }).reduce((p, c) => {
                            return {
                                ...p, ...c
                            }
                        }, {})
                        console.log(newBalances);

                        var myBalance = newBalances[1]?.sum_e_swuamount_ ?? 0;
                        myBalance += allBonuses;
                        this.props.dispatch({
                            type: SET_BALANCES_ACCOUNT, payload:
                            {
                                myVerifiedBalance: myBalance,
                                myUnverifiedBalance: newBalances[0]?.sum_e_swuamount_ ?? 0,
                                myVestedBalance: newBalances[2]?.sum_e_swuamount_ ?? 0
                            }
                        });
                    })            
        }
    })
    }

    metamaskSignIn = async () => {
        const adr = this.state.address;
        return this.metamaskConnect()
            .then(async () => {
                if (typeof web3 !== 'undefined') {                
                let tx = await AuthService.singTransaction(this.state.address?.substring(2));
                return AuthService.signUp(this.state.address, tx, { publicAddress: this.state.address })
                    .then(()=>{
                        Modal.info({
                            content: t('Your metamask wallet is well connected to the platform'),
                            onOk() {},
                          });
                          return AuthService.signIn(this.state.address, tx).then(async (user) => {
                            try {
                                var address = adr
                                var parrainAddress = window.localStorage.getItem('parrainAddress')
                                if (parrainAddress != undefined) {
                                    var parentData = await this.getAccountParent(address);
                                    if (parentData.totalElements == 0) {
                                        var parentAddress = parrainAddress.substring(2);
                                        this.addParent(parentAddress)
                                    }
                                }
                            } catch (addAffError) {
                                console.error("aff error: ", addAffError);
                            }
                            this.getUserBalances();
                            return {
                                user,
                                sig: tx
                            }
                        })
                    })
                    .catch((err) => {
                        return AuthService.signIn(this.state.address, tx).then(async (user) => {
                            try {
                                var address = adr
                                var parrainAddress = window.localStorage.getItem('parrainAddress')
                                if (parrainAddress != undefined) {
                                    var parentData = await this.getAccountParent(address);
                                    if (parentData.totalElements == 0) {
                                        var parentAddress = parrainAddress.substring(2);
                                        this.addParent(parentAddress)
                                    }
                                }
                            } catch (addAffError) {
                                console.error("aff error: ", addAffError);
                            }
                            this.getUserBalances();
                            return {
                                user,
                                sig: tx
                            }
                        })
                    })
                }
            }).then((d) => {
                if (typeof web3 !== 'undefined') {
                this.getAccountAssets();
                this.props.dispatch({ type: SET_USER, payload: { user: d.user } });
                this.isKycValidationNotInitiate().then( () =>{
                    document.getElementById("blockpass-kyc-connect").click();
                });
                }
                //this.props.dispatch({ type: SET_AUTH_TOKEN, payload: btoa(`${this.state.address}:${d.sig}`) });
                //localStorage.setItem('token', btoa(`${this.state.address}:${d.sig}`));
                return Promise.resolve()
            }).catch((err) => {
                console.log(err);
                this.resetApp();
                //this.openErrorModal();
            }).finally(() => {
                if (typeof web3 !== 'undefined') {
                this.setState({
                    registrationModal: false
                })
            }
            })
    }

    metamaskSignUp = async () => {
        return this.metamaskConnect()
            .then(async () => {
                let tx = await AuthService.singTransaction(this.state.address?.substring(2));
                return AuthService.signUp(this.state.address, tx, { publicAddress: this.state.address })
                    .then((user) => {
                        return {
                            user,
                            sig: tx
                        }
                    })
            }).then((d) => {
                this.getAccountAssets();
                this.props.dispatch({ type: SET_USER, payload: { user: d.user } });
                this.getUserBalances();
                //this.props.dispatch({ type: SET_AUTH_TOKEN, payload: btoa(`${this.state.address}:${d.sig}`) });
                //localStorage.setItem('token', btoa(`${this.state.address}:${d.sig}`));
                return Promise.resolve()
            }).catch((err) => {
                console.log(err);
                this.resetApp();
                this.openErrorModal();
            }).finally(() => {
                this.setState({
                    registrationModal: false
                })
            })
    }

    onConnect = async () => {

        /*if (typeof web3 !== 'undefined') {
            window.localStorage.setItem('logout', 'false');
            this.props.dispatch({ type: SET_LOADING, payload: true });
            try {
                const {
                    web3,
                    provider,
                    address,
                    chainId,
                    networkId
                } = await open2web3.connect({ providerOptions });
                this.subscribeProvider(provider);
                this.setState({
                    web3,
                    provider,
                    connected: true,
                    address,
                    chainId,
                    networkId
                });
                this.getAccountAssets();

                try {
                    var parrainAddress = window.localStorage.getItem('parrainAddress')
                    if (parrainAddress != undefined && parrainAddress != address) {
                        var parentData = await this.getAccountParent(address);
                        if (parentData.totalElements == 0) {
                            var parentAddress = parrainAddress.substring(2);
                            let signature;
                            if (web3.currentProvider.isMetaMask !== true) {
                                signature = await window.BinanceChain.request({ method: "eth_sign", params: [address, parentAddress] });
                            } else {
                                signature = await web3.eth.personal.sign(parentAddress, address);
                            }
                            this.addParent(parentAddress, signature, address)
                        }
                    }
                } catch (addAffError) {
                    console.error("aff error: ", addAffError);
                }


                if (this.state.chainId * 1 !== AppNetworkId) {
                    provider
                        .request({
                            method: 'wallet_addEthereumChain',
                            params: [ChainProps],
                        })
                        .then((result) => {
                            console.log("wallet_addEthereumChain", result);
                        })
                        .catch((error) => {
                            if (error.code === 4001) {
                                console.log("We can't encrypt anything without the key.", error);
                            } else {
                                console.error(error);
                            }
                        });
                }

                /!*if (this.state.chainId * 1 !== AppNetworkId) {
                    provider
                        .request({
                            method: 'wallet_switchEthereumChain',
                            params: [ChainProps],
                        })
                        .then((result) => {
                            console.log("wallet_addEthereumChain", result);
                        })
                        .catch((error) => {
                            if (error.code === 4001) {
                                console.log("We can't encrypt anything without the key.", error);
                            } else {
                                console.error(error);
                            }
                        });
                }*!/



            } catch (err) {
                console.log("err message:", err)
                if (err == undefined) {
                    this.setState({
                        modalVisible: true
                    });
                }

            }
            this.props.dispatch({ type: SET_LOADING, payload: false });
        } else {
            this.setState({
                modalVisible: true
            });
        }*/

        this.setState({
            registrationModalKey: (new Date()).toJSON(),
            registrationModal: true
        })
    };

    changeNetwork = async () => {

        window.localStorage.setItem('logout', 'false');
        this.props.dispatch({ type: SET_LOADING, payload: true });
        try {
            const {
                web3,
                provider,
                address,
                chainId,
                networkId
            } = await open2web3.connect({ providerOptions });
            this.subscribeProvider(provider);
            this.setState({
                web3,
                provider,
                connected: true,
                address,
                chainId,
                networkId
            });
            this.getAccountAssets();


            /*if (this.state.chainId * 1 !== AppNetworkId) {
                provider
                    .request({
                        method: 'wallet_addEthereumChain',
                        params: [ChainProps],
                    })
                    .then((result) => {
                        console.log("wallet_addEthereumChain", result);
                    })
                    .catch((error) => {
                        if (error.code === 4001) {
                            console.log("We can't encrypt anything without the key.", error);
                        } else {
                            console.error(error);
                        }
                    });
            }

            if (this.state.chainId * 1 !== AppNetworkId) {
                provider
                    .request({
                        method: 'wallet_switchEthereumChain',
                        params: [ChainProps],
                    })
                    .then((result) => {
                        console.log("wallet_addEthereumChain", result);
                    })
                    .catch((error) => {
                        if (error.code === 4001) {
                            console.log("We can't encrypt anything without the key.", error);
                        } else {
                            console.error(error);
                        }
                    });
            }*/
        }
        catch (e) {
            console.log(e);
        }
        this.props.dispatch({ type: SET_LOADING, payload: false });
    }

    getAccountParent(account) {
        return new Promise(async (resolve, reject) => {
            fetch(apiEndpoint + "/services/LabarakIcoDB/queryExecutor/queries/getChildParent?childAddress=" + account, { hasAuth: false })
                .then(response => response.json())
                .then(data => {
                    resolve(data);
                }).catch(err => {
                    console.error(err);
                    this.props.dispatch({ type: SET_LOADING, payload: false });
                }).catch(err => {
                    console.error(err);
                    this.props.dispatch({ type: SET_LOADING, payload: false });
                });
        });
    }

    addParent(parentAddress, signature, signer) {
        // POST request using fetch with error handling
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(apiEndpoint + "/services/myParrain/parrain?addresseParent=" + parentAddress/* + "&sig=" + signature + "&signer=" + signer*/, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                window.localStorage.removeItem('parrainAddress')
                this.setState({ postId: data.id })
            })
            .catch(error => {
                console.error('Add parent error', error.toString());
            });
    }

    getAccountAssets = async () => {
        this.props.dispatch({ type: SET_WALLET, payload: { address: this.state.address, provider: this.state.provider, chainId: this.state.chainId, networkId: this.state.networkId } });
        if (!this.onEventDebounce) this.onEventDebounce = debounce(this.onEvent, 1000);
        var isRefferalActor = await this.isReferralActor().then(response => response.json()).catch((err) => { });
        this.props.dispatch({ type: SET_ISREFFERAL_ACTOR, payload: { isRefferalActor: isRefferalActor } });
    };

    isReferralActor = async () => {

        var address = this.state.address.substring(2);
        var signature = await getOrCreateSignature(address, this.state.address);
        //if (signature != "") {
        console.log("this.props.store.walletAddress", this.state.address)

        return fetch(apiEndpoint + "/services/myClassification/isRefferralActor?account=" + this.state.address)//+ "&sig=" + signature)

        //}
    }

    subscribeProvider = (provider) => {
        if (!provider || !provider.on || provider.binded) {
            return;
        }
        provider.binded = true;


        const metamaskSignInDeb = debounceg(this.metamaskSignIn, 100);
        provider.on("close", () => this.resetApp());
        // provider.on("message", (...e) => console.log('message', ...e));        
        provider.on("accountsChanged", async (accounts) => {
            console.log("accountsChanged")
            if (!this._ismounted) return;
            this.setState({ address: accounts[0] }, metamaskSignInDeb);

            /*var parrainAddress = window.localStorage.getItem('parrainAddress')
            if(parrainAddress != undefined && parrainAddress != accounts[0]){
                var parentData = await this.getAccountParent(accounts[0]);
                if(parentData.totalElements == 0){
                    var parentAddress = parrainAddress.substring(2);
                    const signature = await this.state.web3.eth.personal.sign(parentAddress, accounts[0]);
                    this.addParent(parentAddress,signature,accounts[0])
                }
            }*/

        });
        provider.on("chainChanged", async (chainId) => {
            if (!this._ismounted) return;
            const { web3 } = this.state;
            //const web3  = this.state;
            const networkId = await this.open2web3?.web3.eth.net.getId();
            this.setState({ chainId, networkId }, metamaskSignInDeb);
        });

        provider.on("networkChanged", async (networkId) => {
            if (!this._ismounted) return;
            const { web3 } = this.state;
            const chainId = await this.open2web3?.web3.eth.chainId();
            console.log("networkId", networkId)
            console.log("chainId", chainId)
            this.setState({ chainId, networkId }, metamaskSignInDeb);
        });
    };

    closeEmailVerificationModal() {
        this.setState({
            emailVerificationModal: false,
            registrationModal: false,
            signUpViewFirst: false,
        })
    }

    signUp(email, password) {
        return AuthService.signUp(email, password).then((data) => {
            this.setState({
                emailVerificationModal: true,
            })
            this.getUserBalances();
            /*this.props.dispatch({ type: SET_AUTH_TOKEN, payload: btoa(`${email}:${password}`) });
            this.props.dispatch({ type: SET_USER, payload: {user: data} });
            localStorage.setItem('token', btoa(`${email}:${password}`));*/
        }).catch(async(err) => {
            console.log(err);
            const arr = await err;
            if(arr?.errors?.error[0]?.parameters[0] === "User already exists..."){
                this.setState({
                    alreadySubscribModal: true,
                })
                this.getUserBalances();
            } else {
                this.resetApp();
                this.setState({
                    faildModalVisible: true
                })
            }
        }).finally(() => {
            this.setState({
                registrationModal: false
            })
        })
    }

    resetPassword = (email) => AuthService.sendPasswordResetEmail(email);

    signIn(email, password, { token } = {}) {
        return AuthService.signIn(email, password, { token }).then((data) => {
            //this.props.dispatch({ type: SET_AUTH_TOKEN, payload: token ?? btoa(`${email}:${password}`) });
            this.props.dispatch({ type: SET_USER, payload: { user: data }, walletAddress: data.publicAddress });
            this.getUserBalances();
            //localStorage.setItem('token', token ?? btoa(`${email}:${password}`));
            fetch(apiEndpoint + "/services/myClassification/isRefferralActor?account=" + email).then(response => response.json())
                .then(isRefferalActor => {
                    this.props.dispatch({ type: SET_ISREFFERAL_ACTOR, payload: { isRefferalActor: isRefferalActor } });
                }).catch((err) => { });
        }).then(()=> {
            let lang = (localStorage.getItem('lang') ?? "fr").toUpperCase();
            fetch(apiEndpoint + `/services/myUsers/loggedInUserlanguage?lang=${lang}`,{
                method: 'PUT',
            });

        }).then( ()=> {
            this.isKycValidationNotInitiate().then( () =>{
                document.getElementById("blockpass-kyc-connect").click();
            });
        }).catch((err) => {
            console.log(err);
            this.resetApp();
            this.openErrorModal();
        }).finally(() => {
            this.setState({
                registrationModal: false
            })
        })
    }

    resetApp = async () => {
        if(this.props.closeMobileMenu) this.props.closeMobileMenu();
        const { web3 } = this.state;
        open2web3?.web3Modal?.clearCachedProvider();
        if (web3 && web3.currentProvider && web3.currentProvider.close) {
            await web3.currentProvider.close();
        }
        this.setState({
            fetching: false,
            address: "",
            web3: null,
            provider: null,
            connected: false,
            chainId: 1,
            networkId: 1,
            assets: [],
            showModal: false,
            pendingRequest: false,
            result: null
        });
        this.props.dispatch({ type: SET_WALLET, payload: { address: null } });
        this.props.dispatch({ type: SET_AUTH_TOKEN, payload: null });
        this.props.dispatch({ type: SET_USER, payload: { user: null, walletConnected: false } });
        window.localStorage.setItem('logout', 'true');
        window.localStorage.removeItem('token');
        if (!window.location.href.includes('verify-account')) {
            this.props.history.push('/'+i18n.language+'/token-sale');
        }
    };

    onEvent = (e) => {
        if (e) {
            console.log("onEvent:", e);
            this.props.dispatch({ type: SET_REFRESH });
        } else {
            console.log("no onEvent:", e);
        }
    }

    verifyTokenExpiration = () => {
        if (localStorage.getItem("tokenExpired") == "true") {
            localStorage.setItem("tokenExpired", "false");
            //resetApplication();
            this.resetApp()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.store.openAuthWindow !== prevProps.store?.openAuthWindow) {
            this.setState({
                registrationModalKey:(new Date()).toJSON(),
                registrationModal: this.props.store.openAuthWindow
            })
        }
        if (this.props.store.openSignUpWindow !== prevProps.store?.openSignUpWindow) {
            this.setState({
                registrationModalKey:(new Date()).toJSON(),
                registrationModal: this.props.store.openSignUpWindow,
                signUpViewFirst: this.props.store.openSignUpWindow
            })
        }
    }

    componentDidMount() {

        window.swu_auth = () => {
            this.setState({
                registrationModalKey: (new Date()).toJSON(),
                signUpViewFirst: false,
                registrationModal: true
            });
        };

        window.swu_signup = () => {
            this.setState({
                registrationModalKey: (new Date()).toJSON(),
                registrationModal: true,
                signUpViewFirst: true
            });
        };

        window.btnWalletConnect = this;

        if (!window.location.pathname.includes("/reset-password")) {
            var interval = setInterval(this.verifyTokenExpiration, 3000);
        }

        var parrainAddress = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).parrain;
        if (parrainAddress != undefined) {
            window.localStorage.setItem('parrainAddress', parrainAddress);
        }
        this._ismounted = true;
        const logout = window.localStorage.getItem('logout');

        let authToken = this.props.store.token ?? localStorage.getItem('token');
        if (authToken) {
            /*
            open2web3.connect({ providerOptions }).then((data) => {
                const {
                    web3,
                    provider,
                    address,
                    chainId,
                    networkId
                } = data;

                fetch(apiEndpoint + `/services/LabarakIcoDB/queryExecutor/queries/selectLoggedInUsername`)
                    .then(response => response.json())
                    .then(user => {
                        var username = user.username
                        let isWalletAddress = web3.utils.isAddress(username);
                        if (isWalletAddress) {
                            this.metamaskSignIn()
                        } else {
                            fetch(apiEndpoint + "/services/myClassification/isRefferralActor?account=" + username).then(response => response.json())
                                .then(isRefferalActor => {
                                    this.props.dispatch({ type: SET_ISREFFERAL_ACTOR, payload: { isRefferalActor: isRefferalActor } });
                                }).catch((err) => { });
                            if (/^\S+@\S+\.\S+$/.test(username)) {
                                //this.signIn(null, null, { token: `${authToken}` })
                            }
                        }
                    }).catch(err => {
                        console.error(err)
                    }).catch(err => {
                        console.error(err)
                    });


                // let username = AuthService.getUsernameFromToken(authToken);
                // let isWalletAddress = web3.utils.isAddress(username);
                // if (isWalletAddress) {
                //     this.metamaskSignIn()
                // } else {
                //     if (/^\S+@\S+\.\S+$/.test(username)) {
                //         this.signIn(null, null, { token: `${authToken}` })
                //     }
                }
            })*/
        } else {
            if (logout != undefined && logout != null && logout !== 'true') {
                this.onConnect();
            }
        }
        this.getUserBalances();
        open2web3.callContractToken('getOwner')
            .then(ownerAddress => this.props.dispatch({ type: SET_OWNER_WALLET, payload: { address: ownerAddress } }))
            .catch(console.error);
        this.onEventDebounce = debounce(this.onEvent, 1000);

        console.log("Subscribe to crowdsale events");

        this.listener = open2web3.contractEventListner(open2web3.getCrowdSaleContractAbi(), CrowsdaleContractAddress, function (error, result) {
            //this.props.dispatch({ type: SET_REFRESH });
            //console.log("event emitted from crowdsale contract", result)
        });

    }

    componentWillUnmount() {
        if (this.state.provider) this.state.provider.binded = false;
        this._ismounted = false;
    }

    onInscrire = async () => {
        this.setState({
            signUpViewFirst:true,
            registrationModalKey: (new Date()).toJSON(),
            registrationModal: true
        })
    
    }

    closeModal = ()=>{
        this.setState({ registrationModal: false, signUpViewFirst: false });
    }

    render() {
        const { t } = this.props;
        const { walletConnected } = this.props.store;

        return (
            <>
                {/*
                    walletConnected && this.props.store.networkId != AppNetworkId && this.props.store.walletAddress ?
                        <Link onClick={this.props.store.networkId != AppNetworkId ? () => this.changeNetwork() : this.onConnect} className="positionT">
                            <div className="btn-switch-network"><Trans i18nKey="Switch to" /> {AppNetworkName}</div>
                        </Link> :
                        <Link to="#" className="btn1" onClick={walletConnected ? this.resetApp : this.onConnect}>
                            {walletConnected ? t("Sign out") : t("Sign In")}
                        </Link>*/
                }
                
                {walletConnected ? null :(
                    <>
                 <Link to="#" className="btn1 a1" onClick={this.onInscrire}>
                    {t("S'inscrire")} 
                 </Link>
                
              </>
                 )}
                    <Link to="#" className="btn1 a2" onClick={walletConnected ? this.resetApp : this.onConnect}>
                
       {walletConnected ? <><div className="iconDecon iconlang dL-none"></div><span className="signO">{t("Sign out")}</span> </>: t("Sign In") }
                    </Link>
                   
                



                <Modal visible={this.state.modalVisible} title={t("Connexion error")} footer={<div style={{ display: 'flex', justifyContent: 'center' }}><button className="btn1" onClick={e => this.setState({ modalVisible: false })} >OK</button></div>} onOk={e => this.setState({ modalVisible: false })} onCancel={e => this.setState({ modalVisible: false })} >
                    <p><Trans i18nKey="Please install metamask to connect to the application" /></p>
                </Modal>

                <Modal className="colorT regisM" visible={this.state.registrationModal} afterClose={() => this.hideGlobalLoginModal()} closable={true} title=" " footer={<></>} 
                onOk={e => this.setState({ registrationModal: false, signUpViewFirst: false })} onCancel={e => this.setState({ registrationModal: false, signUpViewFirst: false })} >
                    <SingUpView
                        key={this.state.registrationModalKey}
                        signUpFirst={this.state.signUpViewFirst}
                        signInMethods={{
                            onMetamaskClick: () => this.metamaskSignIn(),
                            signIn: (email, password) => this.signIn(email, password)
                        }}
                        signUpMethods={{
                            onMetamaskClick: () => this.metamaskSignIn(),
                            signUp: (email, password) => this.signUp(email, password)
                        }}
                        resetPassword={this.resetPassword}
                        closeModal={this.closeModal}
                    />
                </Modal>

                <Modal visible={this.state.emailVerificationModal} title={t("Email Verification Needed")} footer={<div style={{ display: 'flex', justifyContent: 'center' }}><button className="ant-btn ant-btn-primary" onClick={e => this.closeEmailVerificationModal()} >OK</button></div>} onOk={e => this.closeEmailVerificationModal()} onCancel={e => this.closeEmailVerificationModal()} >
                    <p><Trans i18nKey="Your registration is complete, please verify your email address by using the link sent to you to via email" /></p>
                </Modal>

                <Modal visible={this.state.errorModalVisible} title={t("Connexion error")} footer={<div style={{ display: 'flex', justifyContent: 'center' }}><button className="ant-btn ant-btn-primary" onClick={e => this.setState({ errorModalVisible: false })} >OK</button></div>} onOk={e => this.setState({ errorModalVisible: false })} onCancel={e => this.setState({ errorModalVisible: false })} >
                    <p><Trans i18nKey="Nom d'utilisateur ou mot de passe invalide" /></p>
                </Modal>
                <Modal visible={this.state.faildModalVisible} title={t("Connexion error")} footer={<div style={{ display: 'flex', justifyContent: 'center' }}><button className="ant-btn ant-btn-primary" onClick={e => this.setState({ faildModalVisible: false })} >OK</button></div>} onOk={e => this.setState({ faildModalVisible: false })} onCancel={e => this.setState({ faildModalVisible: false })} >
                <p><Trans i18nKey="Problème lors de l'inscription, veuillez contacter le support" /></p>
                </Modal>
                <Modal visible={this.state.alreadySubscribModal} title={t("Connexion error")} footer={<div style={{ display: 'flex', justifyContent: 'center' }}></div>} onOk={e => this.setState({ alreadySubscribModal: false })} onCancel={e => this.setState({ alreadySubscribModal: false })} >
                   <div style={{textAlign:"center"}}>
                    <p><Trans i18nKey="Problème lors de l'inscription, veuillez réessayer ultérieurement" /></p>
                    <p><Trans i18nKey="Ou cliquer sur" /> <button className="ant-btn-primary" onClick={() =>{ this.setState({ alreadySubscribModal: false }); window.swu_auth();}}><Trans i18nKey="Se Connecter" /></button></p>
                    </div>
                </Modal>
            </>
        );
    }

}

const mapState = (store) => ({ store });
const mapDispatch = (dispatch) => ({ dispatch });

export default connect(mapState, mapDispatch)(withRouter(withTranslation()(WalletConnectButton)));
