import React from "react";
import { Alert, Button, Card, Checkbox, Divider, Form, Input, Spin, Modal, Row, Col} from "antd";
import metamask from "../assets/metamask.svg";
import { LockOutlined, VerifiedUser } from "@material-ui/icons";
import '../css/signUpView.css';
import { apiEndpoint } from "./open2web3";
import { Trans, withTranslation } from "react-i18next";
import Recaptcha from "react-recaptcha";

class SingUpView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: this.props.signUpFirst ? 'signUp' : 'signIn',
            blockedMetaMaskButton: false,
            blockedSubmitButton: false,
            isLoading: false,
            recaptcha: false,
            passState: [],
            password:'',
            passwordFocus: false
        }
    }

    t = this.props.t;

    withLoader(action) {
        this.setState({ isLoading: true, blockedMetaMaskButton: true, blockedSubmitButton: true });
        action().then(() => {
            this.setState({ isLoading: false, blockedMetaMaskButton: false, blockedSubmitButton: false });
        })
        .finally(() => {
            this.setState({ isLoading: false });
        })
    }

    verifyCallback = (recaptcha) => {
        this.setState({ recaptcha });
    }

    checkPassword = (_password) => {
        const password = `${_password ? _password : ''}`;
        const list = [];
        if (password.length >= 6) {
            list.push('Min 6 Chars');
        }
        if (password.match(/[a-z]+/)) {
            list.push('Min');
        }
        if (password.match(/[0-9]+/)) {
            list.push('Num');
        }
        if (password.match(/[A-Z]+/)) {
            list.push('Maj');
        }
        if (password.match(/[^A-Za-z0-9]+/)) {
            list.push('Symbole');
        }
        this.setState({ passState: list });
        return list.length;
    }

    passwordHasError = () => {
        const password = this.state.password;

        if (password.length === 0) {
            return false;
        }

        if (password.length < 6) {
            return true;
        }
        if (!password.match(/[a-z]+/)) {
            return true;
        }
        if (!password.match(/[0-9]+/)) {
            return true;
        }
        if (!password.match(/[A-Z]+/)) {
            return true;
        }
        if (!password.match(/[^A-Za-z0-9]+/)) {
            return true;
        }

        return false;
    }
    signUpView = () => {
        const { blockedSubmitButton } = this.state;
        const { signUpMethods, t } = this.props;
        return (
            <div className={"dark_background_modal darkS"} style={{ paddingTop: 10,paddingBottom:10,paddingLeft:5,paddingRight:5 }}>

                <div>
                <h3><Trans i18nKey="Sign Up" /></h3>
                <Form
                    name="basic"
                    labelCol={{ span: 10 }}
                   
                    initialValues={{ remember: true }}
                    onFinish={(v) => this.withLoader(() => signUpMethods.signUp(v.email, v.password1))}
                    onFinishFailed={this.props.onSignupFailure}
                    autoComplete="off"
                >
                    <Form.Item
                        label={t("Email")}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: t('Please input your username!'),
                            },
                            {
                                type: "email",
                                message: t("the email you typed is not formatted")
                            },
                            // ({ getFieldValue }) => ({
                            //     validator(_, value) {
                            //         return new Promise((resolve, reject) => {
                            //             fetch(apiEndpoint + "/services/LabarakIcoDB/queryExecutor/queries/isUsernameUsed?username=" + value, { hasAuth: false })
                            //                 .then(response => response.json())
                            //                 .then(res => {
                            //                     if (res.errors) {
                            //                         resolve();

                            //                     } else {
                            //                         if (res.isExists) {
                            //                             reject(t('User already exists'));
                            //                         } else {
                            //                             resolve();
                            //                         }
                            //                     }

                            //                 })
                            //         })

                            //     },
                            // }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <div className="fixeIcon">
                    <Form.Item
                        label={t("Password")}
                        name="password1"
                        hasFeedback={false}
                        rules={[
                            {
                                required: true,
                                message: "",
                            },
                            {
                                validator: (_, value) => {
                                    if (this.checkPassword(value) >= 5) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject();
                                },
                                message: ''
                            },
                        ]}
                    >
                        <div>
                            <Input.Password 
                                onFocus={ ()=> this.setState({passwordFocus:true})}
                                onBlur={ ()=> this.setState({passwordFocus:false})}
                                onChange={ (event) => this.setState({password:event.target.value})}
                            />
                           
                            <div className="passwordstate">

                                {this.state.passState.map((e, i) => <span key={i} className="activepass" />)}
                                
                            {([...(new Array(5 - this.state.passState.length))]).map((e, i) => <span key={i + 5} />)}

                            </div>
                            
                        </div>

                        
                        

                    </Form.Item>
                    </div>
                    <Row>
                        <Col offset={8}>
                        { (this.state.passwordFocus || this.passwordHasError() ) &&
                            <div className="ant-form-item-explain">
                                <ul>
                                    <li>
                                        <span className={!this.state.password.match(/[a-z]+/) ? 'error-message':'success-message'}>{t("Lower case letters")}</span><br/>
                                    </li>
                                    <li>
                                        <span className={!this.state.password.match(/[A-Z]+/) ? 'error-message':'success-message'}>{t("A capital letter")}</span><br/>
                                    </li>
                                    <li>
                                        <span className={!this.state.password.match(/[0-9]+/) ? 'error-message':'success-message'}>{t("A number")}</span><br/>
                                        </li>
                                    <li>
                                        <span className={!this.state.password.match(/[^A-Za-z0-9]+/) ? 'error-message':'success-message'}>{t("Punctuation marks or special characters (€, #...)")}</span><br/>
                                    </li>
                                    <li>
                                        <span className={this.state.password.length < 6 ? 'error-message':'success-message'}>{t("6 character minimum")}</span><br/>
                                    </li>    
                                </ul>
                            </div>
                            }
                        </Col>


                    </Row>
                   

                   

                    <div  style={{marginBottom: '24px'}}/>
                    <Form.Item
                        name="confirm"
                        label={t('Confirm Password')}
                        style={{ color: "#fff" }}
                        dependencies={['password1']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: t('Please confirm your password!'),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password1') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('The two passwords that you entered do not match!')));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item>
                        <div className="recaptchacn">
                            <Recaptcha sitekey="6LeCTk8dAAAAAJMfauTdXnMj1IyemcM9gCKScj_F" verifyCallback={this.verifyCallback} />
                        </div>
                    </Form.Item>

                    <Form.Item className="ceB2"
                        wrapperCol={{
                            offset: 0,
                            span: 24,
                        }}
                    >
                        <Button disabled={blockedSubmitButton || !this.state.recaptcha} type="primary" htmlType="submit" style={{ marginBottom: ".5em",marginTop: "1.5em"  }}>
                            <Trans i18nKey="Submit" />
                        </Button>
                        <Trans i18nKey="Or" /> <a onClick={() => this.setState({ page: 'signIn', recaptcha: false })}><Trans i18nKey="Sign In with your existing account" /></a>
                    </Form.Item>
                </Form>
                
                </div>
                <div>
                <Divider><Trans i18nKey="Or via" /></Divider>
                <Card


bodyStyle={{ height: 0, padding: "0" }}

onClick={() => {
    if (this.state.blockedMetaMaskButton) return;
    this.withLoader(signUpMethods.onMetamaskClick)
}}
cover={
    <div className="metamaskS">
<img src={metamask} alt="" />
<button className="BtnH"><Trans i18nKey="Connecter mon metamask"/></button>
 </div>
}
>
</Card>
                </div>
               
            </div>
        );
    }

    signInView = () => {
        const { blockedSubmitButton } = this.state;
        const { signInMethods, t } = this.props;
        return (
            <div className={"dark_background_modal darkS"}>
                <div>
                <h3><Trans i18nKey="Sign In" /></h3>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={(v) => this.withLoader(() => signInMethods.signIn(v.username, v.password))}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: t('Please input your Username!') },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                return new Promise((resolve, reject) => {
                                    fetch(apiEndpoint + "/services/LabarakIcoDB/queryExecutor/queries/checkIfAccountConfirmed?username=" + value, { hasAuth: false })
                                        .then(response => response.json())
                                        .then(res => {
                                            if (res.errors) {
                                                resolve();

                                            } else {
                                                if (res?.content[0]?.confirmationMail == false) {
                                                    reject(t('Compte non activé : veuillez consulter vos emails.'));
                                                } else {
                                                    resolve();
                                                }
                                            }

                                        })
                                })

                            },
                        })]}
                    >
                        <Input prefix={<VerifiedUser className="site-form-item-icon" />} placeholder={t("Username")} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: t('Please input your Password!') }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder={t("Password")}
                        />
                    </Form.Item>
                    <Form.Item className="spaceBe">
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox><Trans i18nKey="Remember me" /></Checkbox>
                        </Form.Item>

                        <a className="login-form-forgot" onClick={() => this.setState({ page: 'resetPassword', recaptcha: false  })}>
                            <Trans i18nKey="Forgot password" />
                        </a>
                    </Form.Item>
                    <Form.Item>
                        <div className="recaptchacn">
                            <Recaptcha sitekey="6LeCTk8dAAAAAJMfauTdXnMj1IyemcM9gCKScj_F" verifyCallback={this.verifyCallback} />
                        </div>
                    </Form.Item>
                    <Form.Item className="ceB">
                        <Button disabled={blockedSubmitButton || !this.state.recaptcha} type="primary" htmlType="submit" className="login-form-button" style={{ marginBottom: ".5em" }}>
                            <Trans i18nKey="Log in" />
                        </Button>
                        <Trans i18nKey="Or" /> <a onClick={() => this.setState({ page: 'signUp', recaptcha: false  })}><Trans i18nKey="Register a new account" /></a>
                    </Form.Item>
                </Form>
                
                </div>
                <div>
                <Divider><Trans i18nKey="Or via" /></Divider>
                
                <Card
                    hoverable
                    bodyStyle={{ height: 0, padding: "0" }}
                    onClick={() => {
                        if (this.state.blockedMetaMaskButton) return;
                        this.withLoader(signInMethods.onMetamaskClick)
                    }}
                    cover={
                        <div className="metamaskS">
                    <img src={metamask} alt="" />
                    <button className="BtnH"><Trans i18nKey="Connecter mon metamask"/></button>
                     </div>
                }
                >
                </Card>
                </div>
              
            </div>
        );
    }

    resetPasswordView = () => {
        const { t } = this.props;
        return (
            <div className={"dark_background_modal"}>
                <Form
                    name="resetpassword"
                    className="login-form"
                    onFinish={(v) => this.withLoader(async () => {
                        try {
                            let response = await this.props.resetPassword(v.username);
                            this.props.closeModal();
                            if(response.status === 200 || response.status === 404 ) {
                                Modal.success({
                                    content: t('An email has been sent to you.')
                                });
                            }
                        } catch (e) {
                            console.log(e);
                            this.setState({ message: { type: 'error', message: t('Error occurred, please check the email address.') } });
                        }
                        setTimeout(() => {
                            this.setState({ message: null });
                        }, 3000);
                    })}
                >
                    <Form.Item
                        name="username"
                        label={t("Email")}
                        rules={[
                            { required: true, message: t('Please input your Username!') },
                            { type: "email", message: t("the email you typed is not formatted")}
                        ]}
                    >
                        <Input prefix={<VerifiedUser className="site-form-item-icon" />} placeholder={t("Email")} />
                    </Form.Item>
                    {this.state.message ? <div className="alertmessage"><Alert {...this.state.message} /></div> : null}

                    <Form.Item>
                        <div className="recaptchacn"> 
                            <Recaptcha sitekey="6LeCTk8dAAAAAJMfauTdXnMj1IyemcM9gCKScj_F" verifyCallback={this.verifyCallback} />
                        </div>
                    </Form.Item>

                    <Form.Item className="ceB">
                        {/* disabled={blockedSubmitButton} */}
                        <Button type="primary" disabled={!this.state.recaptcha} htmlType="submit" className="login-form-button" style={{ marginBottom: ".5em" }}>
                            <Trans i18nKey="Submit" />
                        </Button>
                        {/* onClick={() => this.setState({ signUp: true })} */}
                        <Trans i18nKey="Or" /> <a onClick={() => this.setState({ page: 'signIn', recaptcha: false  })}><Trans i18nKey="Sign In with your existing account" /></a>
                    </Form.Item>
                </Form>
            </div>
        );
    }

    render() {
        const { page } = this.state;
        const { t } = this.props;
        let content = null;
        switch (page) {
            case 'signUp':
                content = this.signUpView();
                break;
            case 'resetPassword':
                content = this.resetPasswordView();
                break;
            default:
                content = this.signInView();
                break;
        }
        return <Spin spinning={this.state.isLoading}>
            <div className="regis" key={page}>
                <h2 style={{ textAlign: "center" }}><Trans i18nKey={t({ signUp: "Inscription", signIn: "Connexion", resetPassword: 'Forgot password' }[page])} /></h2>
                {content}
            </div>
        </Spin>
    }
}



export default withTranslation()(SingUpView);
