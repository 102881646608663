import fetchIntercept from 'fetch-intercept';
import store from './reducer/Store'

const unregister = fetchIntercept.register({
    request: function (url, config) {
        // Modify the url or config here
        return [url, {
            ...(config ?? {}),
            headers: {
                ...(config?.headers ?? {}),
                //...(!(config?.hasAuth === false) ?  {authorization :store.getState().token ? 'Basic ' + store.getState().token : config?.headers?.authorization} : {})
                ...(!(config?.hasAuth === false) ? { WM_AUTH_TOKEN: localStorage.getItem('token') ? localStorage.getItem('token') : config?.headers?.authorization } : {})
            }
        }];
    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {
        const response2 = response.clone();
        response2.json().then(responseData => {

            if (responseData?.errors?.error[0]?.message == 'Invalid Token,Failed to authenticate with the given token' && (localStorage.getItem("tokenExpired")=="false" || localStorage.getItem("tokenExpired")==null)) {
                localStorage.setItem("tokenExpired", "true")
            }

        })
        // Modify the reponse object
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error

        console.log(error);
        return Promise.reject(error);
    }
});

export default unregister;
