import moment from 'moment';
import { useState, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SET_AVAILABLE_TOKENS, SET_DECIMALS, SET_DEPLOYMENT_INFO, SET_ICO_ROUND, SET_MONTANT_OBJECTIF, SET_MY_BALANCE, SET_SALES_PERCENT, SET_SYMBOL, SET_TOKEN_PRICE, SET_TOTAL_PHASE1, SET_TOTAL_SOLD, SET_TOTAL_SUPPLY, SET_WEIRAISED } from '../reducer/Main.reducer';
import { apiEndpoint, blockExplorerUrl, CrowdSaleInitBlock, CrowsdaleContractAddress, open2web3, TokenContractAddress } from './open2web3';
import NumberFormater from './NumberFormater';
import i18n from '../i18n';

function AntBoxInfo({ type, store, dispatch, valueOnly, initialText, initialValue, initialSubtitle }) {
  const { t } = useTranslation();
  const [time, setTime] = useState('');
  let title = '';
  let subtitle = '';
  let value = '';
  let descripiron = '';
  let icon = 'far fa-money-bill-alt';
  let color = 'bg-lightBlue-500';
  let shortName = '';

  useLayoutEffect(() => {
    if (type !== 'TotalSupplay') return;


    fetch(apiEndpoint + "/services/LabarakIcoDB/queryExecutor/queries/tokenInfos", { hasAuth: false })
      .then(response => response.json())
      .then(data => {
        const supply = data.minted
        const decimals = data.decimals
        const symbol = data.symbol
        const tokensPhase1 = data.tokensPhase1
        const icoRound = data.phaseActuelle
        dispatch({ type: SET_TOTAL_SUPPLY, payload: supply })
        dispatch({ type: SET_DECIMALS, payload: decimals })
        dispatch({ type: SET_SYMBOL, payload: symbol })
        dispatch({ type: SET_TOTAL_PHASE1, payload: tokensPhase1 })
        dispatch({ type: SET_ICO_ROUND, payload: icoRound })

        fetch(apiEndpoint + "/services/myExternalPayment/allSalesCurrentPhase", { hasAuth: false })
          .then(responsePay => responsePay.json())
          .then(dataPay => {
            //let totalSold = parseFloat(dataPay.content[0].swuAmount) + parseFloat(dataPay.content[0].bonus);
            let totalSold = dataPay;
            //totalSold = 0
            dispatch({ type: SET_TOTAL_SOLD, payload: totalSold })
            const amount = (parseFloat(tokensPhase1) - totalSold)
            dispatch({ type: SET_AVAILABLE_TOKENS, payload: amount })
            const salesPercent = Math.round(totalSold / tokensPhase1 *100)
              dispatch({ type: SET_SALES_PERCENT, payload: salesPercent })
          })

      })
      .catch(console.error);

/*
    open2web3.symbol()
      .then(payload => dispatch({ type: SET_SYMBOL, payload }))
    open2web3.decimals()
      .then(payload => dispatch({ type: SET_DECIMALS, payload }))
*/
    fetch(apiEndpoint + "/services/LabarakIcoDB/queryExecutor/queries/getLiquidValues", { hasAuth: false }).then(result => result.json()).then(data => {
      let payload = data.content[0].montantObjectif;
      dispatch({ type: SET_MONTANT_OBJECTIF, payload })
    });
  }, [type]);

  useLayoutEffect(() => {
    if (type !== 'montantObjectif') return;
    fetch(apiEndpoint + "/services/LabarakIcoDB/queryExecutor/queries/getLiquidValues", { hasAuth: false }).then(result => result.json()).then(data => {
      dispatch({ type: SET_MONTANT_OBJECTIF, value: 10000 })
    });
  }, [type]);

  useLayoutEffect(() => {
    if (type !== 'MyBalance' || !store.walletAddress) return;
    console.log("call", type);
    /*open2web3.balanceOf(store.walletAddress)
      .then(payload => dispatch({ type: SET_MY_BALANCE, payload }))*/
  }, [type, store.walletAddress, store.key]);

  useLayoutEffect(() => {
    if (type !== 'Price') return;
    console.log("call", type);
    /*open2web3.contractDeployedEvent(CrowsdaleContractAddress, CrowdSaleInitBlock, CrowdSaleInitBlock)
      .then(payload => dispatch({ type: SET_DEPLOYMENT_INFO, payload }))
    */
    fetch(apiEndpoint + "/services/LabarakIcoDB/queryExecutor/queries/tokenInfos", { hasAuth: false })
      .then(response => response.json())
      .then(data => {
        const payload = data.tokenPrice
        dispatch({ type: SET_TOKEN_PRICE, payload })
      })
      .catch(console.error);

  }, [type]);

  useLayoutEffect(() => {
    if (type !== 'TokensAvailable') return;
    console.log("call", type);
    //open2web3Provider.getBalanceByAccount(store.price.id)
    /*open2web3.balanceOf(CrowsdaleContractAddress)
      .then(payload => dispatch({ type: SET_AVAILABLE_TOKENS, payload }))*/

    /*fetch(apiEndpoint + "/services/LabarakIcoDB/queryExecutor/queries/totalExternalPayements")
    .then(response => response.json())
    .then(data => {
      const payload = data.tokenPrice
      dispatch({ type: SET_AVAILABLE_TOKENS, payload })
    })*/
  }, [type, store.deploymentEvent.id, store.key]);

  useLayoutEffect(() => {
    if (type !== 'Time') return;
    console.log("call", type);
    var eventTime = moment(new Date((store.deploymentEvent.closingTime == null ? 1651197786 : store.deploymentEvent.closingTime) * 1000));
    console.log("closingTime", store.deploymentEvent.closingTime)
    var interval = 1000;
    const t = setInterval(function () {
      var currentTime = moment(new Date());
      var duration = moment.duration(eventTime.diff(currentTime));
      var days = Math.floor(duration.asDays());
      duration.subtract(moment.duration(days, 'days'));
      var hours = duration.hours();
      duration.subtract(moment.duration(hours, 'hours'));
      var minutes = duration.minutes();
      duration.subtract(moment.duration(minutes, 'minutes'));
      var seconds = duration.seconds();
      const getVal = (val, unite) => {
        return val + ' ' + unite + (val > 1 ? 's' : '');
      }
      if (duration) {
        setTime(`${getVal(days, t('jour'))}, ${getVal(hours, t('heure'))} ${getVal(minutes, t('minute'))} ${getVal(seconds, t('seconde'))}`)
      }
    }, interval);

    return () => clearInterval(t);
  }, [type, store.deploymentEvent.closingTime]);


  useLayoutEffect(() => {
    /*if (type !== 'Weiraised' || !open2web3.web3) return;
    try {
      open2web3.weiraised()
        .then(payload => dispatch({ type: SET_WEIRAISED, payload: open2web3.web3.utils.fromWei(payload + "", "ether") }));
    } catch (err) {
      console.error(err);
    }*/
  }, [type, open2web3.web3, store.key]);

  switch (type) {
    case 'TotalSupplay':
      title = t('Total issued tokens');
      subtitle = title;
      value = NumberFormater(store.totalSupply);
      descripiron = 'BEEP';
      color = 'bg-red-500';
      icon = 'fas fa-users';
      shortName = t('Tokens émis');
      break;
    case 'TotalPhase1':
      title = t('Total tokens');
      subtitle = title;
      value = NumberFormater(parseFloat(parseFloat(store.totalPhase1).toFixed(2)));
      descripiron = 'BEEP';
      color = 'bg-red-500';
      icon = 'fas fa-users';
      shortName = t('Total tokens');
      break;
    case 'TotalTokensSold':
      title = t('Total tokens sold');
      subtitle = title;
      value = NumberFormater(parseFloat(store.totalSold.toFixed(2)));
      descripiron = 'BEEP';
      color = 'bg-red-500';
      icon = 'fas fa-users';
      shortName = t('Tokens sold');
      break;

    case 'MyBalance':
      if (!store.walletAddress) return null;
      title = t('My balance');
      subtitle = title;
      value = parseFloat(store.myBalance.toFixed(2)).toLocaleString();
      descripiron = 'BEEP';
      color = 'bg-pink-500';
      break;
    case 'myVerifiedBalance':
      if (!store.user?.username) return null;
      title = t('My verified balance');
      subtitle = title;
      value = NumberFormater(store.myVerifiedBalance.toFixed(2));
      descripiron = 'BEEP';
      color = 'bg-pink-500';
      break;
    case 'myUnverifiedBalance':
      if (!store.user?.username) return null;
      title = t('My Unverified balance');
      subtitle = title;
      value = NumberFormater(store.myUnverifiedBalance.toFixed(2))
      descripiron = 'BEEP';
      color = 'bg-pink-500';
      break;
    case 'myVestedBalance':
      if (!store.user?.username) return null;
      title = t('My Vested balance');
      subtitle = title;
      value =  parseFloat(store.myVestedBalance.toFixed(2)).toLocaleString()
      descripiron = 'BEEP';
      color = 'bg-pink-500';
      break;
  
    case 'myBalanceViaEmail':
      if (!store.user?.username) return null;
      title = initialText ?? t('My balance');
      subtitle = title;
      value = initialValue ?? store.myBalance;
      descripiron = initialSubtitle ?? 'BEEP';
      color = 'bg-pink-500';
      break;

    case 'Price':
      title = t('Current Token Price');
      subtitle = title;
      // todo use NumberFormater instead, but he will cause null as return in somes case
      value = i18n.language === "fr" ? (!store.price  + ' €' ? store.price  + ' €' : store.price.replace('.',',') ) :store.price   + ' €';
      //descripiron = store.symbol;
      //descripiron = "BUSD";
      icon = 'fas fa-tag';
      // shortName = 'Prix';
      break;

    case 'TokensAvailable':
      title = t('Tokens available');
      subtitle = title;
      value = store.availableTokens == undefined ? '--' : NumberFormater(store.availableTokens);
      descripiron = 'BEEP';
      color = 'bg-orange-500';
      shortName = t('Tokens available');
      break;

    case 'phaseObjective':
      title = t('Objectif de la phase');
      subtitle = title;
      value = store.montantObjectif == undefined ? '--' : store.montantObjectif;
      //descripiron = store.symbol;
      descripiron = "€";
      color = 'bg-orange-500';
      shortName = t('Objectif de la phase');
      break;

    case 'Time':
      title = t('Close in');
      subtitle = title;
      value = time;
      color = 'bg-orange-500';
      icon = 'far fa-clock';
      break;

    case 'Wallet':
      if (!store.walletAddress) return null;
      title = t('Mon wallet');
      subtitle = title;
      value = store.walletAddress.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
      value = value[1] + '...' + value[3];
      color = 'bg-pink-500';
      icon = 'fas fa-wallet';
      break;

    case 'WalletContract':
      if (!store.walletAddress) return null;
      title = t('Contrat address');
      subtitle = title;
      value = TokenContractAddress.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
      value = value[1] + '...' + value[3];
      color = 'bg-pink-500';
      icon = 'fas fa-wallet';
      break;

    case 'Weiraised':
      title = t('Collection fund');
      subtitle = title;
      value = store.weiraised;
      descripiron = 'DAI';
      color = 'bg-pink-500';
      icon = 'fas fa-wallet';
      break;

    case 'investmentRange':
      title = t('Investment range');
      subtitle = title;
      value = "Min 2K€/Max 80k€";
      break;

    default:
      return null;
  }


  const handleClick = () => {
    if (type === 'WalletContract') {
      window.open(blockExplorerUrl + '/token/' + TokenContractAddress, '_blank');
    }
  }

  if (valueOnly) return `${shortName} ${shortName ? ':' : ''} ${value ? value : ''} ${descripiron ? descripiron : ''}`;

  return (
    <div className={`AntBoxInfo AntBoxInfo-${type}`} onClick={handleClick}>
      {/*<span><span className="glyphicon glyphicon-tag"></span></span>*/}
      <div className="centerInfo">
        <h3>{value} {descripiron}</h3>
        <span>{title}</span>
      </div>
    </div>
  )
}


const mapState = (store) => ({ store });
const mapDispatch = (dispatch) => ({ dispatch });


AntBoxInfo.LinkedCard = connect(mapState, mapDispatch)(AntBoxInfo);

AntBoxInfo.TotalSupplay = props => <AntBoxInfo.LinkedCard {...props} type={'TotalSupplay'} />;
AntBoxInfo.TotalPhase1 = props => <AntBoxInfo.LinkedCard {...props} type={'TotalPhase1'} />;
AntBoxInfo.TotalTokensSold = props => <AntBoxInfo.LinkedCard {...props} type={'TotalTokensSold'} />;
AntBoxInfo.MyBalance = props => <AntBoxInfo.LinkedCard {...props} type={'MyBalance'} />;
AntBoxInfo.MyBalanceViaEmail = props => <AntBoxInfo.LinkedCard {...props} type={'MyBalanceViaEmail'} />;
AntBoxInfo.Price = props => <AntBoxInfo.LinkedCard {...props} type={'Price'} />;
AntBoxInfo.TokensAvailable = props => <AntBoxInfo.LinkedCard  {...props} type={'TokensAvailable'} />;
AntBoxInfo.Time = props => <AntBoxInfo.LinkedCard  {...props} type={'Time'} />;
AntBoxInfo.Wallet = props => <AntBoxInfo.LinkedCard  {...props} type={'Wallet'} />;
AntBoxInfo.Weiraised = props => <AntBoxInfo.LinkedCard  {...props} type={'Weiraised'} />;
AntBoxInfo.WalletContract = props => <AntBoxInfo.LinkedCard  {...props} type={'WalletContract'} />;
AntBoxInfo.phaseObjective = props => <AntBoxInfo.LinkedCard  {...props} type={'phaseObjective'} />;
AntBoxInfo.myVerifiedBalance = props => <AntBoxInfo.LinkedCard  {...props} type={'myVerifiedBalance'} />;
AntBoxInfo.myUnverifiedBalance = props => <AntBoxInfo.LinkedCard  {...props} type={'myUnverifiedBalance'} />;
AntBoxInfo.myVestedBalance = props => <AntBoxInfo.LinkedCard  {...props} type={'myVestedBalance'} />;
AntBoxInfo.investmentRange = props => <AntBoxInfo.LinkedCard  {...props} type={'investmentRange'} />;



export default AntBoxInfo;

