import { useEffect, useState } from "react";
import { apiEndpoint } from "./open2web3";

function HasParent(props){
    const [hasParent, SetHasParent] = useState(false);

    useEffect( () =>{
        fetch(apiEndpoint + "/services/myExternalPayment/connectedUserHasParent")
        .then(response => {
            if(!response.ok){
                throw new Error('Something went wrong');
            }
            return response.json()
        })
        .then(data => SetHasParent(data));
    },[]);

    return (
        hasParent ? <small className="bonuss">(Bonus 2% Actif)</small> : <div></div>
    );
}

export default HasParent;